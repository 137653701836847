import React from 'react'

import Layout from '../components/Layout'
import { Result, Button } from 'antd'
import { navigate } from 'gatsby'
import i18n from '../config/i18n'

const NotFoundPage = ({ location, pageContext: { locale } }: any) => (
  <Layout location={location} locale={locale}>
    <Result
      status="404"
      title="404"
      subTitle={i18n[locale].desc404}
      extra={
        <Button onClick={handleOnHomeClick} type="primary">
          {i18n[locale].backHome}
        </Button>
      }
    />
  </Layout>
)

function handleOnHomeClick() {
  navigate('/')
}

export default NotFoundPage
